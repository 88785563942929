import React from "react"
import Layout from "../components/layout"

const ThankYouPage = () => (
  <Layout>
    <div className="contact">
      <h1>Contact</h1>
      <p>Thank you for your submission!</p>
      <div class="box ">
        {/* <img loading="lazy" class="alignnone size-full " src="../../marioGlasses.jpg" /> */}
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
